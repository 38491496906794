<template>
  <div class="content-1">
    <div class="bg"></div>
    <div class="content content-1-1">
      EVENT DETAILS
    </div>
    <div class="content content-1-2">
      <div class="hr left-hr"></div>
      <div class="hr right-hr"></div>
      万人添X
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.content-1{
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 450px;
  margin: 0 auto;
  background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/TenThousand/content-1.png");
  background-size: 100% 100%;
  position: relative;
  .bg{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    left: 0;
    top: 0;
  }
  .content{
    width: 100%;
    text-align: center;
    font-weight: bold;
    position: absolute;
  }
  .content-1-1{
    font-size: 19px;
    color: #fff;
    top: 130px;
  }

  .content-1-2{
    font-size: 36px;
    top: 180px;
    color: #fff;
    .hr{
      width: 200px;
      height: 1px;
      position: absolute;
      border: 0;
      padding-top: 1px;
    }
    .left-hr{
      background: linear-gradient(to right, transparent, #d0d0d5);
      left: 295px;
      top: 29px;
    }
    .right-hr{
      background: linear-gradient(to left, transparent, #d0d0d5);
      right: 295px;
      top: 29px;
    }
  }
}
</style>