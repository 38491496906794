<template>
  <div class="loading" v-if="view">
    <img src="https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-2-9/loading-2.5s-249px.gif">
  </div>
</template>

<script>
export default {
  data(){
    return{
      view:false
    }
  },
  methods:{
    open(){
      this.view=true
    },
    close(){
      this.view=false
    }
  }
}
</script>

<style scoped lang="scss">
.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  img{
    width: 70px;
    height: 70px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -35px;
    margin-top: -35px;
  }
}
</style>