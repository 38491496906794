<template>
  <div class="home">
    <Header></Header>
    <content_1></content_1>
    <content_2 :childData="data" :styleClass="styleClass" :one_Data="one_Data" :dataIndex="dataIndex"></content_2>

    <wrth_1 :initImage="initImage" v-if="styleClass=='万人添画'"></wrth_1>
    <wrth_2 :LookVideo="LookVideo" :initData="indexData" :ImgScene="ImgScene" v-if="styleClass=='万人添画'"></wrth_2>

    <wrts_1 :ImgPoster="ImgPoster" v-if="styleClass=='万人添书'"></wrts_1>
    <wrts_2 :ImgPoster="ImgPoster" :indexData="indexData" v-if="styleClass=='万人添书'"></wrts_2>
    <wrts_3 :ImgScene="ImgScene" :indexData="indexData" v-if="styleClass=='万人添书'"></wrts_3>
    <Footer></Footer>
    <Loading ref="Loading"></Loading>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import content_1 from "@/views/TenThousand/com/content_1";
import content_2 from "@/views/TenThousand/com/content_2";
import wrth_1 from "@/views/TenThousand/com/wrth/content_3";
import wrth_2 from "@/views/TenThousand/com/wrth/content_4";

import wrts_1 from "@/views/TenThousand/com/wrts/wrts_1";
import wrts_2 from "@/views/TenThousand/com/wrts/wrts_2";
import wrts_3 from "@/views/TenThousand/com/wrts/wrts_3";

import Loading from "@/views/TenThousand/com/Loading";

import {initData} from "@/api/Activity";

export default {
  data() {
    return {
      listheight: '',
      styleClass: '万人添画',
      data: [],
      initData: {},
      initImage: [],
      indexData: {},
      one_Data: '',
      dataIndex: 0,
      LookVideo: '',
      ImgScene: [],
      ImgPoster: []
    };
  },
  components: {
    Header,
    Footer,
    content_1,
    content_2,
    wrth_1,
    wrth_2,

    wrts_1,
    wrts_2,
    wrts_3,

    Loading
  },
  mounted() {
    this.init();
    let section = this.$router.currentRoute.hash.replace("#", "");
    if (section && section == 'book') {
      this.styleClass = '万人添书'
    }
  },
  methods: {
    UpStartClass(val) {
      this.styleClass = val
      this.init()
    },
    init() {
      let styleClass = this.styleClass
      initData({styleClass}).then(res => {
        let data = res.data

        this.dataIndex = 0

        this.initData = JSON.parse(JSON.stringify(data))

        let one_Data = data[0].Quarter
        this.one_Data = one_Data

        data.splice(0, 1)
        this.data = data

        this.getData()
      })
    },
    getData() {
      this.$refs.Loading.open()
      this.initImage=[]
      this.LookVideo=''
      this.ImgScene=[]

      setTimeout(()=>{
        if (this.styleClass == '万人添画') {
          let data = JSON.parse(JSON.stringify(this.initData))

          let initData = data[this.dataIndex]
          this.indexData = initData

          let initImage = []
          initImage.push(initData.Img_1)
          initImage.push(initData.Img_2)
          initImage.push(initData.Img_3)
          initImage.push(initData.Img_4)
          initImage.push(initData.Img_5)

          this.initImage = initImage

          let LookVideo = initData.LookVideo
          this.LookVideo = LookVideo

          let ImgScene = JSON.parse(initData.ImgScene)

          this.ImgScene = ImgScene

          this.$refs.Loading.close()

        } else {
          let data = JSON.parse(JSON.stringify(this.initData))
          let initData = data[this.dataIndex]

          initData.ImgPoster = JSON.parse(initData.ImgPoster)
          initData.ImgScene = JSON.parse(initData.ImgScene)

          this.ImgPoster = initData.ImgPoster
          this.ImgScene = initData.ImgScene
          this.indexData = initData

          this.$refs.Loading.close()
        }
      },800)

    },
    upIndex(index) {
      this.dataIndex = index
      this.getData()
    }
  }

}
</script>

<style scoped lang="scss">


.home {
  background-color: #000;
  width: 100%;
}


</style>
