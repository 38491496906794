<template>
  <div class="content_3">
    <div class="main">
      <div class="title-1">Merged paintings</div>
      <div class="title-2">万人添画
        <div class="left"></div>
        <div class="right"></div>
      </div>
      <div class="text">
        <p>“万人添画”活动，是由3QLAB主办的多人线上共创NFT活动，现已进行三季。</p>
        <p>在“万人添画”活动中，所有参与者都可以自选坐标，完成个人随性画作，最终共同构成一幅数字艺术NFT作品。</p>
        <p>
          对于画作，主办方只明确主题，对内容不作预期和限制，允许创作者们自由自在抒发情感，表达哲思，碰撞灵感，勾勒属于自己的独家记忆。</p>
        <p>"众创”是“万人添画”的核心因子。颠覆以往文艺作品遥不可及的印象，让更多人欣赏且参与其中，不仅是文艺创作自身发展的需要，更是时代大势<br>所趋与潮流所在。而与更多领域的人产生奇妙的联结，凝结不同人群的时代印记，则是“万人添画”绘下的愿景。
        </p>
        <p>创作者不仅有国内知名的艺术家、航空航天领域的工程师、著作等身的教授、崭露头角的设计师、稚嫩活泼的孩子以及真诚朴实的服务员。他们通过<br>“万人添画”连结在一起，共同进行了这场空前的群体智慧的艺术实验。
        </p>
        <p>
          主办方希望以此打破传统文艺创作形式的枷锁，让更多的人，特别是普通人也能参与到文艺创作中，一起探索未知与可能性的边界。</p>
      </div>
    </div>
    <div class="imgNav">
      <div class="block-1">
        <img :src="initImage[0]">
      </div>
      <div class="block-2">
        <img :src="initImage[1]">
      </div>
      <div class="block-1">
        <img :src="initImage[2]">
      </div>
      <div class="block-2">
        <img :src="initImage[3]">
      </div>
      <div class="block-1">
        <img :src="initImage[4]">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initImage: {
      type: Array,
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped lang="scss">
.content_3 {
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 950px;
  margin: 0 auto;
  position: relative;
  background: linear-gradient(180deg, #3E75E4 0%, #7C5FD7 70%, #000 100%);

  .main {
    width: 1000px;
    height: 523px;
    position: absolute;
    left: 50%;
    margin-left: -500px;

    .title-1 {
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 16px;
      position: absolute;
      top: 40px;
    }

    .title-2 {
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 30px;
      font-weight: bold;
      position: absolute;
      top: 80px;
      @mixin hr {
        width: 350px;
        height: 1px;
        position: absolute;
        border: 0;
        padding-top: 1px;
      }

      .left {
        @include hr;
        background: linear-gradient(to right, transparent, #d0d0d5);
        left: 40px;
        top: 20px;
      }

      .right {
        @include hr;
        background: linear-gradient(to left, transparent, #d0d0d5);
        right: 40px;
        top: 20px;
      }
    }

    .text {
      width: 850px;
      height: 200px;
      color: #fff;
      position: absolute;
      left: 50%;
      margin-left: -425px;
      top: 150px;
      letter-spacing: 3px;
      font-size: 13px;
      line-height: 23px;
      text-align: center;
    }
  }

  .imgNav {
    width: 100%;
    height: 387px;
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-around;

    img {
      width: 100%;
      height: 100%;
    }

    .block_img {
      width: 100%;
      height: 100%;
    }

    .block-1 {
      width: 200px;
      height: 253px;
      background-color: #FFFFFF;
      margin-top: 50px;
    }

    .block-2 {
      width: 225px;
      height: 387px;
      background-color: #FFFFFF;
    }
  }
}
</style>
