<template>
  <div class="content_7">
    <div class="content-1">{{ indexData.TitleEnglish }}</div>
    <div class="content-2">
      {{ indexData.Quarter }}：“万人添书 · {{ indexData.IntroduceTitle }}”
      <div class="right-hr"></div>
    </div>
    <div class="content-3">
      <div class="swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item,index) in ImgPoster" :key="index">
            <img :src="item">
            <div class="sw_bg"></div>
          </div>
        </div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import 'swiper/dist/css/swiper.css';

export default {
  props: {
    ImgPoster: {
      type: Array
    },
    indexData: {
      type: Object
    }
  },
  mounted() {
    setTimeout(() => {
      this.init()
    }, 1000)
  },
  methods: {
    init() {
      new Swiper(".swiper", {
        slidesPerView: 4,
        spaceBetween: '5%',
        prevButton: '.swiper-button-prev',
        nextButton: '.swiper-button-next',
      });
    }
  }
}
</script>

<style scoped lang="scss">
.content_7 {
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 700px;
  margin: 0 auto;
  background-size: 100% 100%;
  position: relative;
  margin-top: 50px;

  .content-1 {
    color: #A3A3A3;
    font-size: 20px;
    position: absolute;
    left: 0;
    top: 0;
  }

  .content-2 {
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    margin-top: 20px;
    width: 100%;
    height: 40px;
    position: absolute;
    left: 0;
    top: 30px;

    .right-hr {
      width: 650px;
      height: 1px;
      position: absolute;
      border: 0;
      padding-top: 1px;
      background: linear-gradient(to right, transparent, #d0d0d5);
      right: 0;
      top: 17px;
    }
  }

  .content-3 {
    width: 100%;
    height: 500px;
    position: absolute;
    left: 0;
    top: 130px;

    .swiper {
      width: 100%;
      height: 450px;
      margin-bottom: 20px;
      overflow: hidden;

      .swiper-wrapper {
        height: 100%;
        .swiper-slide {
          height: 450px;
          color: #ffffff;
          position: relative;

          img {
            width: 300px;
            height: 450px;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 10;
          }
          .sw_bg {
            width: 300px;
            height: 100%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.8) 99%);
            position: absolute;
            left: 0;
            top: 0;
            z-index: 10;
          }
        }
      }

      .swiper-button-prev {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l4.2%2C4.2L8.4%2C22l17.8%2C17.8L22%2C44L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") center 50%/50% 50% no-repeat;
        left: 5px;
        position: absolute;
      }

      .swiper-button-next {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L5%2C44l-4.2-4.2L18.6%2C22L0.8%2C4.2L5%2C0z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") center 50%/50% 50% no-repeat;
        right: -5px;
      }
    }
  }

}
</style>
