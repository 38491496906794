<template>
  <div class="content_4">
    <div class="content-1">
      {{initData.TitleEnglish}}
    </div>
    <div class="content-2">
      <span>{{ initData.Quarter }}:</span>“{{ initData.Title }}”
      <div class="right-hr"></div>
    </div>
    <div class="content-3">
      <div class="video">
        <video :src="LookVideo" controls></video>
      </div>
      <div class="main">
        <div class="left">
          <div class="bg-Big">
            <img :src="initData.MainImg" @click="openImage(initData.MainImg)">
          </div>
          <div class="bg-1"></div>
          <div class="bg-2"></div>
          <div class="bg-3"></div>
          <div class="bg-4"></div>
          <div class="img-1">
            <img :src="initData.Img_1" @click="openImage(initData.Img_1)">
          </div>
          <div class="img-2">
            <img :src="initData.Img_2" @click="openImage(initData.Img_2)">
          </div>
          <div class="img-3">
            <img :src="initData.Img_3" @click="openImage(initData.Img_3)">
          </div>
          <div class="img-4">
            <img :src="initData.Img_4" @click="openImage(initData.Img_4)">
          </div>
          <div class="img-5">
            <img :src="initData.Img_5" @click="openImage(initData.Img_5)">
          </div>
        </div>
        <div class="right">
          <div class="title">活动介绍</div>
          <div class="text" v-html="initData.SelectedText"></div>
        </div>
      </div>
    </div>
    <div class="content-4">
      <div class="swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item,index) in ImgScene" :key="index">
            <img :src="item"  @click="openImage(item)">
          </div>
        </div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
      <div class="title">活动现场</div>
    </div>
    <LookImage ref="LookImage"></LookImage>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
import LookImage from "@/components/LookImage";

export default {
  mounted() {

  },
  watch:{
    ImgScene:{
      handler() {
        setTimeout(()=>{
          this.init()
        },1000)
      },
      deep: true,
      immediate: true
    }
  },
  props: {
    LookVideo: {
      type: String,
    },
    initData: {
      type: Object
    },
    ImgScene: {
      type: Array
    }
  },
  components: {
    LookImage
  },
  methods: {
    openImage(val) {
      this.$refs.LookImage.init(val)
    },

    init() {
      new Swiper(".swiper", {
        slidesPerView: 4,
        spaceBetween: '2%',
        prevButton: '.swiper-button-prev',
        nextButton: '.swiper-button-next',
      });
    }
  }
}
</script>

<style scoped lang="scss">
.content_4 {
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 2000px;
  background-size: 100% 100%;
  position: relative;
  margin: 100px auto 0;

  .content-1 {
    font-size: 16px;
    color: #A3A3A3;
    width: 100%;
  }

  .content-2 {
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    margin-top: 20px;
    width: 100%;
    height: 40px;
    position: relative;

    span {
      margin-right: 20px;
    }

    .right-hr {
      width: 800px;
      height: 1px;
      position: absolute;
      border: 0;
      padding-top: 1px;
      background: linear-gradient(to right, transparent, #d0d0d5);
      right: 0;
      top: 17px;
    }
  }

  .content-3 {
    width: 100%;
    height: 800px;
    margin: 250px auto 550px;
    background: linear-gradient(180deg, #3E75E4 0%, #7C5FD7 50%, #000 100%);
    border-radius: 15px;
    position: relative;

    .video {
      width: 1100px;
      height: 540px;
      background-color: #FFFFFF;
      position: absolute;
      left: 50%;
      margin-left: -550px;
      top: -200px;

      video {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }

    .main {
      position: absolute;
      left: 0;
      top: 400px;
      width: 100%;
      height: 900px;

      img {
        width: 100%;
        cursor: pointer;
        transition: 0.3s all;
      }

      img:hover {
        transform: scale(1.25)
      }

      .left {
        width: 700px;
        height: 900px;
        position: absolute;
        left: 0;
        top: 0;
        @mixin img-135 {
          width: 135px;
          height: 135px;
          background-color: #3E75E4;
          position: absolute;
          overflow: hidden;
        }
        @mixin img-91 {
          width: 91px;
          height: 91px;
          background: linear-gradient(180deg, #3E75E4 0%, #7C5FD7 100%);
          position: absolute;
          overflow: hidden;
        }
        @mixin img-45 {
          width: 45px;
          height: 45px;
          background: linear-gradient(180deg, #3E75E4 0%, #7C5FD7 100%);
          position: absolute;
          overflow: hidden;
        }

        .bg-Big {
          width: 350px;
          height: 600px;
          background-color: #3E75E4;
          position: absolute;
          left: 160px;
          top: 160px;
          overflow: hidden;

          img {
            height: 100%;
          }
        }

        .bg-1 {
          @include img-91;
          left: 260px;
          top: 49px;
        }

        .bg-2 {
          @include img-45;
          left: 530px;
          top: 230px;
        }

        .bg-3 {
          @include img-45;
          left: 465px;
          top: 780px;
        }

        .bg-4 {
          @include img-45;
          left: 95px;
          top: 380px;
        }

        .img-1 {
          @include img-135;
          top: 5px;
          left: 375px;
        }

        .img-2 {
          @include img-135;
          top: 230px;
          left: 5px;
        }

        .img-3 {
          @include img-135;
          left: 530px;
          top: 290px;
          width: 182px;
          height: 182px;
        }

        .img-4 {
          @include img-135;
          left: 530px;
          top: 490px;
          width: 91px;
          height: 91px;
        }

        .img-5 {
          @include img-135;
          top: 780px;
          left: 310px;
        }
      }

      .right {
        width: 450px;
        height: 900px;
        position: absolute;
        right: 0;
        top: 0;
        color: #fff;

        .title {
          font-size: 25px;
          margin-bottom: 40px;
        }

        .text {
          width: 338px;
          height: 600px;
        }
      }
    }
  }

  .content-4 {
    width: 100%;
    height: 140px;

    .swiper {
      width: 100%;
      height: 200px;
      margin-bottom: 20px;
      overflow: hidden;
      position: relative;
      .swiper-wrapper {
        height: 100%;
        .swiper-slide {
          width: 100%;
          height: 200px;
          color: #ffffff;
          background-color: #A3A3A3;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .swiper-button-prev {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l4.2%2C4.2L8.4%2C22l17.8%2C17.8L22%2C44L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") center 50%/50% 50% no-repeat;
        left: 5px;
        position: absolute;
      }

      .swiper-button-next {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L5%2C44l-4.2-4.2L18.6%2C22L0.8%2C4.2L5%2C0z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") center 50%/50% 50% no-repeat;
        right: -5px;
        position: absolute;
      }
    }

    .title {
      width: 100%;
      text-align: center;
      position: absolute;
      font-size: 17px;
      color: #A3A3A3;
    }
  }
}
</style>