<template>
  <div class="wrts_1">
    <div class="main">
      <div class="title-1">Merged novels</div>
      <div class="title-2">万人添书
        <div class="left"></div>
        <div class="right"></div>
      </div>
      <div class="text">
        <p>“万人添书”是一场基于交叉学科背景的群体智慧实验，通过区块链技术保障群体写作的版权</p>
        <p>及利益分配，让“万人”共同创作文学作品。敬畏创作，尊重阅读，让人人都能成为创作者。</p>
      </div>
    </div>
    <div class="imgNav">
      <div class="block-1">
        <div class="title">创作</div>
        <div class="hr"></div>
        <p>以三人为上限的小型群体创作工坊为<br>砖石，围绕同一主线共创精彩故事</p>
      </div>
      <div class="block-1">
        <div class="title">版权</div>
        <div class="hr"></div>
        <p>通过区块链技术让个人的产出存证，<br>
          永久保护创作版权</p>
      </div>
      <div class="block-1">
        <div class="title">记录</div>
        <div class="hr"></div>
        <p>原创的贡献值系统记录个人的在群体<br>
          中产出贡献，为利益分配保驾护航</p>
      </div>
    </div>
    <div class="imgNav_1">
      <div class="block-1">
      <img :src="ImgPoster[0]">
      </div>
      <div class="block-2">
        <img :src="ImgPoster[1]">
      </div>
      <div class="block-1">
        <img :src="ImgPoster[2]">
      </div>
      <div class="block-2">
        <img :src="ImgPoster[3]">
      </div>
      <div class="block-1">
        <img :src="ImgPoster[4]">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ImgPoster: {
      type: Array
    },
  }
}
</script>

<style scoped lang="scss">
.wrts_1 {
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 900px;
  margin: 0 auto;
  position: relative;
  background: linear-gradient(180deg, #3E75E4 0%, #7C5FD7 70%, #000 100%);

  .main {
    width: 1000px;
    height: 523px;
    position: absolute;
    left: 50%;
    margin-left: -500px;

    .title-1 {
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 16px;
      position: absolute;
      top: 40px;
    }

    .title-2 {
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 30px;
      font-weight: bold;
      position: absolute;
      top: 80px;
      @mixin hr {
        width: 350px;
        height: 1px;
        position: absolute;
        border: 0;
        padding-top: 1px;
      }

      .left {
        @include hr;
        background: linear-gradient(to right, transparent, #d0d0d5);
        left: 40px;
        top: 20px;
      }

      .right {
        @include hr;
        background: linear-gradient(to left, transparent, #d0d0d5);
        right: 40px;
        top: 20px;
      }
    }

    .text {
      width: 850px;
      height: 200px;
      color: #fff;
      position: absolute;
      left: 50%;
      margin-left: -425px;
      top: 150px;
      letter-spacing: 3px;
      font-size: 13px;
      line-height: 23px;
      text-align: center;
    }
  }

  .imgNav {
    width: 960px;
    height: 387px;
    position: absolute;
    left: 50%;
    margin-left: -480px;
    top: 250px;
    display: flex;
    justify-content: space-around;


    .block-1 {
      width: 210px;
      height: 253px;
      margin-top: 50px;
      color: #fff;

      .title {
        text-align: center;

        font-size: 20px;
      }

      .hr {
        width: 50%;
        border: 0;
        padding-top: 1px;
        margin: 0 auto;
        margin-top: 15px;
        background: linear-gradient(to right, transparent, #d0d0d5, transparent);
      }

      p {
        text-align: center;
        font-size: 13px;
        line-height: 28px;
      }
    }
  }

  .imgNav_1 {
    width: 100%;
    height: 387px;
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-around;

    img {
      width: 100%;
      height: 100%;
    }

    .block-1 {
      width: 200px;
      height: 253px;
      background-color: #FFFFFF;
      margin-top: 50px;
    }

    .block-2 {
      width: 225px;
      height: 387px;
      background-color: #FFFFFF;
    }
  }
}
</style>
