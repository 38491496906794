<template>
  <div class="content_7">
    <div class="content-1">
      <div class="text">“{{ indexData.SloganTitle }}”</div>
    </div>
    <div class="content-2">
      <div class="left">
        <div class="left-1"><h2>活动介绍</h2>
          <div v-html="indexData.IntroduceText"></div>
        </div>
        <div class="title">《{{ indexData.IntroduceTitle }}》</div>
        <div class="goText" @click="openWorkLink">点击阅读</div>
      </div>
      <div class="right">
        <div class="title">活动现场</div>
        <div class="img-1">
          <img :src="ImgScene[0]">
        </div>
        <div class="img-2">
          <img :src="ImgScene[1]">
        </div>
        <div class="img-3">
          <img :src="ImgScene[2]">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    ImgScene: {
      type: Array
    },
    indexData: {
      type: Object
    }
  },
  methods: {
    openWorkLink() {
      window.open(this.indexData.WorkLink);
    }
  }
}
</script>

<style scoped lang="scss">
.content_7 {
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 1000px;
  margin: 0 auto;
  background-size: 100% 100%;
  position: relative;
  margin-top: 50px;

  .content-1 {
    width: 696px;
    height: 58px;
    background: linear-gradient(180deg, #3E75E4 0%, #7C5FD7 100%);
    border-radius: 25px;
    position: relative;
    margin: 0 auto;

    .text {
      background-color: #000;
      width: 694px;
      height: 54px;
      line-height: 54px;
      border-radius: 25px;
      color: #fff;
      text-align: center;
      font-size: 18px;
      margin: 0 auto;
      position: absolute;
      left: 1px;
      top: 2px;
    }
  }

  .content-2 {
    width: 100%;
    height: 780px;
    margin-top: 100px;

    .left {
      width: 358px;
      height: 683px;

      .left-1 {
        width: 450px;
        height: auto;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-30/border-1.png");
        color: #fff;
        line-height: 25px;
        padding: 10px 20px;
        font-size: 14px;
      }

      .title {
        width: 100%;
        text-align: center;
        font-size: 24px;
        color: #fff;
        margin-top: 50px;
      }

      .goText {
        color: #000;
        width: 330px;
        height: 40px;
        background-color: #FFFFFF;
        border-radius: 40px;
        text-align: center;
        line-height: 40px;
        margin: 0 auto;
        margin-top: 40px;
        cursor: pointer;

      }
    }

    .right {
      width: 563px;
      height: 683px;
      background: linear-gradient(180deg, #3E75E4 0%, #7C5FD7 44%, #000000 100%);
      border-radius: 21px;
      border: 3px solid;
      position: absolute;
      right: 0;
      top: 150px;

      .title {
        width: 100%;
        color: #fff;
        text-align: center;
        font-size: 19px;
        position: absolute;
        left: 0;
        top: 40px;
      }

      @mixin img {
        background-color: #d0d0d5;
        position: absolute;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .img-1 {
        width: 220px;
        height: 150px;
        left: 50px;
        top: 160px;

        @include img;
      }

      .img-2 {
        width: 200px;
        height: 280px;
        left: 300px;
        top: 120px;
        @include img;
      }

      .img-3 {
        width: 500px;
        height: 300px;
        left: 30px;
        top: 470px;
        @include img;
      }
    }
  }

}
</style>
