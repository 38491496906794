<template>
  <div class="LookImage" v-if="view">
    <div class="close" @click="close"></div>
    <img
        ref="image"
        class="img"
        @mousedown="handleMouseDown"
        :style="imgStyle"
        :src="currentImg"
    />
  </div>
</template>

<script>

export default {
  data() {
    return {
      view: false,
      currentImg: 'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
      transform: {
        scale: 1,
        deg: 0,
        offsetX: 0,
        offsetY: 0,
        enableTransition: false
      }
    }
  },
  mounted() {

  },
  computed: {
    imgStyle() {
      const {scale, deg, offsetX, offsetY, enableTransition} = this.transform;

      const style = {
        transform: `scale(${scale}) rotate(${deg}deg)`,
        transition: enableTransition ? 'transform .3s' : '',
        'margin-left': `${offsetX}px`,
        'margin-top': `${offsetY}px`
      };

      return style;
    },
  },
  methods: {
    init(url) {
      this.view = true
      setTimeout(() => {
        document.addEventListener('mousewheel', this._mouseWheelHandler, false);
        document.body.style.overflow = 'hidden'
        this.currentImg = url
        this.reset()
        this.getImage()
      })
    },
    getImage() {
      let img = this.currentImg
      let imgObj = new Image();

      let clientWidth = document.body.clientWidth
      let clientHeight = document.body.clientHeight

      imgObj.src = img;
      imgObj.onload = () => {
        let width = imgObj.width
        let height = imgObj.height

        let sumWidth = (clientWidth - width) / 2
        let sumHeight = (clientHeight - height) / 2
        this.transform.offsetX = sumWidth
        this.transform.offsetY = sumHeight

        if (width >= 6000) {
          this.transform.scale = 0.19
        }else if (width >= 5000){
          this.transform.scale = 0.23
        }else if (width >= 4000){
          this.transform.scale = 0.27
        }else if (width >= 3000){
          this.transform.scale = 0.31
        }else if (width >= 2000){
          this.transform.scale = 0.35
        }
      };
    },
    reset() {
      this.transform = {
        scale: 1,
        deg: 0,
        offsetX: 0,
        offsetY: 0,
        enableTransition: false
      };
    },
    handleMouseDown(e) {
      if (this.loading || e.button !== 0) return;

      const {offsetX, offsetY} = this.transform;
      const startX = e.pageX;
      const startY = e.pageY;


      this._dragHandler = this.rafThrottle(ev => {
        this.transform.offsetX = offsetX + ev.pageX - startX;
        this.transform.offsetY = offsetY + ev.pageY - startY;
      });

      this.clearHandler = () => {
        window.removeEventListener('mousemove', this._dragHandler, false);
      }

      window.addEventListener('mousemove', this._dragHandler, false);
      window.addEventListener('mouseup', this.clearHandler, false);

      e.preventDefault();
    },
    rafThrottle(fn) {
      let locked = false;
      return function (...args) {
        if (locked) return;
        locked = true;
        window.requestAnimationFrame(() => {
          fn.apply(this, args);
          locked = false;
        });
      };
    },
    _mouseWheelHandler(e) {
      const delta = e.wheelDelta ? e.wheelDelta : -e.detail;

      if (delta > 0) {
        this.handleActions('zoomIn', {
          zoomRate: 0.015,
          enableTransition: false
        });
      } else {
        this.handleActions('zoomOut', {
          zoomRate: 0.015,
          enableTransition: false
        });
      }
    },
    handleActions(action, options = {}) {
      if (this.loading) return;
      const {zoomRate, rotateDeg, enableTransition} = {
        zoomRate: 0.2,
        rotateDeg: 90,
        enableTransition: true,
        ...options
      };
      const {transform} = this;
      switch (action) {
        case 'zoomOut':
          if (transform.scale > 0.2) {
            transform.scale = parseFloat((transform.scale - zoomRate).toFixed(3));
          }
          break;
        case 'zoomIn':
          transform.scale = parseFloat((transform.scale + zoomRate).toFixed(3));
          break;
        case 'clocelise':
          transform.deg += rotateDeg;
          break;
        case 'anticlocelise':
          transform.deg -= rotateDeg;
          break;
      }
      transform.enableTransition = enableTransition;
    },
    close() {
      this.view = false
      document.removeEventListener('mousewheel', this._mouseWheelHandler, false);
      document.body.style.overflow = ''
      this.currentImg=''
    },
  }
}
</script>

<style scoped lang="scss">
.LookImage {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;

  .close {
    background-image: url("./img/close.png");
    width: 30px;
    height: 30px;
    background-size: 100% 100%;
    position: absolute;
    right: 50px;
    top: 50px;
    cursor: pointer;
  }

  .img {
    margin: 0 auto;
  }
}
</style>