<template>
  <div class="content_2">
    <div class="nav-Start">
      <div class="block" v-bind:class="{block_bg:styleClass=='万人添画'}">
        <div class="hr"></div>
        <div class="title" @click="UpStartClass('万人添画')">万人添画</div>
        <div class="data" v-if="styleClass=='万人添画'" v-bind:class="{open_block:dataIndex==0}" @click="index_Set(0)">
          {{ one_Data }}
        </div>
      </div>

      <div class="block" v-bind:class="{block_bg:styleClass=='万人添书'}">
        <div class="hr"></div>
        <div class="title " @click="UpStartClass('万人添书')">万人添书</div>
        <div class="data" v-if="styleClass=='万人添书'" v-bind:class="{open_block:dataIndex==0}" @click="index_Set(0)">
          {{ one_Data }}
        </div>
      </div>


    </div>
    <div class="nav">
      <div class="block">
        <div class="div" v-if="styleClass=='万人添画'">
          <div class="data" v-for="(item,index) in childData" :key="index"
               v-bind:class="{open_block:dataIndex==(index+1)}" @click="index_Set(index+1)">{{ item.Quarter }}
          </div>
        </div>
      </div>
      <div class="block">
        <div class="div" v-if="styleClass=='万人添书'">
          <div class="data" v-for="(item,index) in childData" :key="index"
               v-bind:class="{open_block:dataIndex==(index+1)}" @click="index_Set(index+1)">{{ item.Quarter }}
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      StyleClass: '万人添画',
    }
  },
  props: {
    childData: {
      type: Array,
    },
    styleClass: {
      type: String,
    },
    one_Data: {
      type: String,
    },
    dataIndex: {
      type: Number,
    }
  },
  methods: {
    index_Set(index) {
      this.$parent.upIndex(index)
    },
    UpStartClass(val) {
      if (val != this.StyleClass) {
        this.StyleClass = val
        this.$parent.UpStartClass(val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.content_2 {
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: auto;
  margin: 0 auto;
  position: relative;

  .open_block {
    background-color: #FFFFFF;
    color: #000 !important;
  }

  .nav-Start {
    width: 100%;
    height: 57px;
    position: relative;
    display: flex;
    justify-content: space-around;

    .block_bg {
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/TenThousand/content-1-1.png");
    }

    .block {
      width: 233px;
      height: 57px;
      background-size: 233px 57px;
      background-repeat: no-repeat;
      position: relative;

      .hr {
        width: 120px;
        height: 1px;
        border: 1px solid;
        border-image: radial-gradient(circle, rgba(255, 255, 255, 1), rgba(0, 0, 0, 1)) 1 1;
        position: absolute;
        left: 50%;
        top: -50px;
        margin-left: -60px;
      }

      .title {
        width: 100%;
        text-align: center;
        position: absolute;
        height: 40px;
        line-height: 40px;
        color: #fff;
        left: 0;
        top: -40px;
        font-size: 20px;
        cursor: pointer;
      }

      .data {
        text-align: center;
        font-size: 12px;
        width: 65px;
        height: 20px;
        margin: 0 auto;
        line-height: 20px;
        border: 1px solid #fff;
        border-radius: 30px;
        padding: 10px 20px;
        margin-top: 10px;
        color: #fff;
        cursor: pointer;
      }

      .data:hover {
        background-color: #FFFFFF;
        color: #000;
      }

    }
  }

  .nav {
    width: 100%;
    height: auto;
    background-color: #3E75E4;
    display: flex;
    justify-content: space-around;

    .block {
      width: 233px;

      .data {
        text-align: center;
        font-size: 12px;
        width: 65px;
        height: 20px;
        margin: 0 auto;
        line-height: 20px;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 30px;
        padding: 10px 20px;
        margin-top: 10px;
        margin-bottom: 10px;
        cursor: pointer;
      }

      .data:hover {
        background-color: #FFFFFF;
        color: #000;
      }
    }
  }
}
</style>
